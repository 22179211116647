import { useGAEvents } from './useGAEvents';

interface StepOneProps {
  categoryId: number;
  groupId: number;
  kind: string;
}

interface StepTwoProps extends StepOneProps {
  photos: string[];
  condition: string;
  immediateAvailable: string[];
  localPickup: string[];
  courierDelivery: string[];
  priceFixed: string[];
  exchange: string[];
  locationId: string;
  phoneNumberSelected: string[];
}

interface StepThreeProps extends StepTwoProps {
  highlighted: string;
  promoLink: string;
  promoVideo: string;
  promoType: string;
  price: string;
  currency: string;
}

interface StepFourProps extends StepThreeProps {
  declarationType: string;
}

const useAdSaveEvents = () => {
  const { createBaseUserObject, sendGAEventWithData, userStats } =
    useGAEvents();

  const createAdSaveUserObject = () => ({
    ...createBaseUserObject(),
    is_first_ad: userStats.isFirstAd,
  });

  const createIndexedRecord = (
    prefix: string,
    items: Array<{ condition: boolean; value: string }>
  ): Record<string, string> => {
    return items.reduce((acc, { condition, value }, index) => {
      if (condition) {
        acc[`${prefix}_${index + 1}`] = value;
      }
      return acc;
    }, {});
  };

  const createDeliveryType = (
    localPickup: string[],
    courierDelivery: string[]
  ) => {
    return createIndexedRecord('delivery_type', [
      { condition: localPickup.includes('yes'), value: 'licno preuzimanje' },
      { condition: courierDelivery.includes('yes'), value: 'dostava' },
    ]);
  };

  const createAddons = (
    highlighted: string,
    promoLink: string,
    promoVideo: string
  ) => {
    return createIndexedRecord('addon', [
      { condition: highlighted === 'yes', value: 'istaknut oglas' },
      { condition: promoLink === 'yes', value: 'link u oglasu' },
      { condition: promoVideo === 'yes', value: 'video u oglasu' },
    ]);
  };

  const includesYes = (value: string[]) => value.includes('yes');
  const parsePrice = (price: string) =>
    price === '' ? 0 : parseInt(price, 10);
  const getPromotionType = (promoType: string) =>
    promoType === 'none' ? 'standardna vidljivost' : promoType;

  const createStepOneData = ({ categoryId, groupId, kind }: StepOneProps) => ({
    type: kind,
    category: categoryId.toString(),
    group: groupId.toString(),
    user: createAdSaveUserObject(),
  });

  const createStepTwoData = (props: StepTwoProps) => ({
    total_images: props.photos.length.toString(),
    condition: props.condition,
    availability: includesYes(props.immediateAvailable) ? 'dostupno odmah' : '',
    delivery_type: createDeliveryType(props.localPickup, props.courierDelivery),
    price_fixed: includesYes(props.priceFixed),
    exchange: includesYes(props.exchange),
    location_name: props.locationId,
    add_phone_number: includesYes(props.phoneNumberSelected),
  });

  const createStepThreeData = (props: StepThreeProps) => ({
    promotion_type: getPromotionType(props.promoType),
    value: parsePrice(props.price),
    currency: props.currency,
    addons: createAddons(props.highlighted, props.promoLink, props.promoVideo),
  });

  const createStepFourData = (props: StepFourProps) => ({
    identification: props.declarationType,
  });

  type StepNumber = 1 | 2 | 3 | 4;

  const adSaveStepSuccess = (
    event: string,
    props: StepOneProps | StepTwoProps | StepThreeProps | StepFourProps,
    step: StepNumber
  ) => {
    const dataToPush = {
      event,
      ...createStepOneData(props as StepOneProps),
    };

    if (step >= 2) {
      Object.assign(dataToPush, createStepTwoData(props as StepTwoProps));
    }

    if (step >= 3) {
      Object.assign(dataToPush, createStepThreeData(props as StepThreeProps));
    }

    if (step >= 4) {
      Object.assign(dataToPush, createStepFourData(props as StepFourProps));
    }

    sendGAEventWithData(dataToPush);
  };

  const adSaveStepOneSuccess = (props: StepOneProps) =>
    adSaveStepSuccess('add_category', props, 1);

  const adSaveTwoSuccess = (props: StepTwoProps) =>
    adSaveStepSuccess('add_ad', props, 2);

  const adSaveStepThreeSuccess = (props: StepThreeProps) =>
    adSaveStepSuccess('add_promotion', props, 3);

  const adSaveStepFourSuccess = (props: StepFourProps) =>
    adSaveStepSuccess('ad_posted', props, 4);

  const clickNewAdEvent = () => {
    const dataToPush = {
      event: 'post_an_ad',
      user: createAdSaveUserObject(),
    };
    sendGAEventWithData(dataToPush);
  };

  return {
    clickNewAdEvent,
    adSaveStepOneSuccess,
    adSaveTwoSuccess,
    adSaveStepThreeSuccess,
    adSaveStepFourSuccess,
  };
};

export default useAdSaveEvents;
